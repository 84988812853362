.slider {
	position: relative;

	.slick-dots {
		width: auto;
		bottom: auto;
		top: 2rem;
		right: 2rem;

		li {
			display: block;

			button {
				display: block;
				width: 15px;
				height: 15px;
				background: @terceary-color;
				border: 1px solid @terceary-color;

				&:hover {
					background: @primary-color;
				}

				&:before {
				  display: none;
				}
			}

			&.slick-active {
				button {
					background: @primary-color;
				}
			}
		}
	}
}