@import "../../core/core.less";

@import "modules/header.less";
@import "modules/footer.less";
@import "modules/slide.less";

.newsletter{
	.container{
		position: relative;

		.box-newsletter{
			position: absolute;
		    width: 100%;
		    z-index: 3;
		    background: #fff;
		    border-radius: 3px;
		    padding: 1.5rem;
		    margin-top: -30px;
		    box-shadow: 3px 5px 24px 1px #5a5757;
		}
	}
}

.factory {
	position: relative;
	padding: 16rem 0 8rem;
	background: @primary-color;

	@media(max-width: 768px) {
		text-align: center;
	}

	.cover {
		
		@media(max-width: 768px) {
			display: block;
			margin: 2rem auto;
			margin-bottom: 0;
		}
	}


	.title {
		.reset-list;
		text-transform: uppercase;
		color: @terceary-color;
		font-weight: 500;
	}

	.text {
		color: @terceary-color;
		font-weight: 400;
	}
}


.environment {
	position: relative;
	padding: 8rem 0;
	padding-bottom: 4rem;
	background: @terceary-color;

	.title {
		.reset-list;
		color: @seventernary-color;
		font-weight: bold;
		text-transform: uppercase;

		&:after {
			display: block;
			content: "";
			width: 150px;
			height: 2px;
			margin: 1rem auto;
			background: @eightternary-color;
		}
	}

	.subtitle {
		color: @nineternary-color;
	}

	.text,
	.text-page {
		color: @nineternary-color;
	}

	.text-page {
		margin-bottom: 4rem;
	}

	.cover {
		display: block;
		margin: 0rem auto;
	}

	.box-left,
	.box-right {
		margin: 6rem 0;
	}

	.box-left {
		text-align: right;

		@media(max-width: 765px) {
			text-align: center;
			margin: 4rem 0;
		}
	}

	.box-right {
		text-align: left;

		@media(max-width: 765px) {
			text-align: center;
			margin: 4rem 0;
		}
	}
}

.products {
	position: relative;
	padding: 8rem 0;
	background: @tenternary-color;


	.title-section {
		.reset-list;
		text-transform: uppercase;
		color: @seventernary-color;
	}
	
	.tabs-links {
		.reset-list;
		margin: 4rem 0; 
		text-align: center;

		li {
			.inline-block;
			vertical-align: middle;
			margin: 1rem;

			a {
				color: @seventernary-color;
				font-size: 1.7rem;
				font-weight: 600;

				&:after {
					display: block;
					margin-top: .5rem;
					content: "";
					width: 0;
					height: 5px;
					background: @secondary-color;
					.transition;
				}

				&:hover,
				&.active {

					text-decoration: none;

					&:after {
						width: 100%;
					}
				}
			}
		}
	}

	.box {
		padding: .3rem;

		article {
			background: @terceary-color;
			text-align: center;
		}

		@media(max-width: 480px) {
			width: 100%;
		}
	}

	.slick-dots {
		bottom: -5rem;

		li {
			button {
				display: block;
				width: 20px;
				height: 20px;
				background: @terceary-color;
				border-radius: 50%;
				border: 1px solid @quartenary-color;

				&:hover {
					background: @quartenary-color;
				}

				&:before {
				  display: none;
				}
			}

			&.slick-active {
				button {
					background: @quartenary-color;
				}
			}
		}
	}
}

.budget {
	position: relative;
	padding: 8rem 0;
	background: url(../../img/bg-budget.jpg) no-repeat center top fixed;
	background-size: cover;

	.title-section {
		.reset-list;
		text-transform: uppercase;
		color: @terceary-color;
		font-weight: 700;
	}

	.text {
		margin: 2rem 0;
		color: @terceary-color;
	}

	.btn-budget {
		.inline-block;
		margin-top: 2rem;
		padding: 1.5rem 4rem;
		background: @secondary-color;
		text-transform: uppercase;
		color: @terceary-color;
		border-radius: 5px;
		font-size: 1.8rem;

		&:hover {
			background: darken(@secondary-color, 5);
			text-decoration: none;
		}
	}
}

.contacts {
	position: relative;
	padding: 8rem 0;


	.title-section {
		.reset-list;
		color: @seventernary-color;
		font-weight: bold;
		text-transform: uppercase;

		&:after {
			display: block;
			content: "";
			width: 50px;
			height: 2px;
			margin: 1rem auto;
			background: @eightternary-color;
		}
	}

	.form-row {
		margin: 1rem 0;

		input,
		select,
		textarea{
			width: 100%;
			padding: 1rem;
			border-radius: 5px;
			border: 1px solid fade(@nineternary-color, 30);
		}

		label {
			display: block;
			font-weight: 500;
			color: @seventernary-color;

			&.error {
				font-weight: 400;
				font-size: 1.3rem;
				color: @secondary-color;
			}
		}

		button {
			padding: 1.5rem 3rem;
			border-radius: 5px;
			background: @primary-color;
			color: @terceary-color;
			border: 0;
			text-transform: uppercase;
			.transition;

			&:hover {
				background: darken(@primary-color, 5);
			}
		}
	}
}

#mapa {
	width: 100%;
	min-height: 450px;
}