header {
	position: relative;
	padding-bottom: 2rem;

	.nav-top-links {
		position: relative;
		margin-bottom: 2rem;
		padding: 2rem 0;
		min-height: 64px;
		background: @quartenary-color;	

		.info-links {
			.reset-list;

			li {
				.inline-block;
				margin: 0  2rem;
				list-style: none;

				&:first-child {
					margin-left: 0;
				}

				a {
					display: block;
					color: @terceary-color;
					font-size: 1.6rem;
					font-weight: 300;

					.fa {
						.inline-block;
						margin-right: 1rem;
						color:@primary-color;
						font-size: 2rem;
					}

					&:hover {
						text-decoration: none;
					}

					@media(max-width: 480px) {
						font-size: 1.2rem;

						.fa {
							margin-right: .2rem;
							font-size: 1.6rem;
						}
					}
				}
			}

			@media(max-width: 765px) {
				text-align: center;
				width: 100%;
			}
		}
	}

	.social-networks {
		float: right;
		.reset-list;

		li {
			.inline-block;
			vertical-align: middle;
			list-style: none;
			margin: 0 1rem;

			a {
				display: block;
				color: @terceary-color;
				span {
					display: block;
    				padding: .2rem 0;
					width: 23px;
				    height: 23px;
				    background: #fff;
				    background: @terceary-color;
					border-radius: 50%;
					color: @quartenary-color;
				    text-align: center;
				}

				&:hover {
					text-decoration: none;

					span {
						background: @primary-color;
					}
				}
			}

			button {
				border: 0;
				background: none;
				color: @terceary-color;
			}

			&:first-child {
				padding-right: 1.5rem;
				border-right:  1px solid @terceary-color;
			}
		}

		@media(max-width: 765px) {
			margin-top: 2rem;
			float: none;
			text-align: center;
			width: 100%;
		}
	}

	.search {
		position: absolute;
		top: -500px;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 1rem 0;
		background: @quartenary-color;
		.transition;

		input {
			width: 100%;
			padding: 1rem 2rem;
			background: @terceary-color;
			border: 1px solid @terceary-color;
			border-radius: 5px;
		}

		.btn-submit {
			position: absolute;
			top: 0.3rem;
		    right: 2rem;
		    border: 0;
		    background: none;
		    font-size: 2.5rem;
		    color: @primary-color;
		}

		.btn-search-close {
			position: absolute;
		    top: 100%;
		    right: 1rem;
		    border: 0;
		    background: @quartenary-color;
		    padding: .5rem 2rem;
		    line-height: 1rem;
		    border-radius: 0px 0px 5px 5px;
		    color: @terceary-color;
		}
	}

	.total-cart {
		float: right;
		margin-left: 1rem;
	}

	.cart {
		.fa {
		 	font-size: 2rem;
		}
	}

	.menu {
		float: right;

		ul {
			.reset-list;

			li {
				.inline-block;
				margin: 4.5rem 2rem;
				list-style: none;

				a {
					font-size: 1.7rem;
					color: @fiveternary-color;
					.transition;

					&:hover {
						text-decoration: none;
						color: @primary-color;
					}
				}

				&:last-child {
					margin-right: 0;
				}

				@media(max-width: 1200px) {
					margin: 3.5rem 1rem; 
				}
			}
		}

		@media(max-width: 992px) {
			position: fixed;
			top: 0;
			right: -300px;
			width: 250px;
			height: 100%;
			padding: 2rem;
			overflow-y: auto;
			background: @quartenary-color;
			z-index: 10;
			.transition;	

			ul {
				li {
					display: block;
					margin: 1rem 0;

					a {
						display: block;
						color: @terceary-color;
					}
				}
			}
		}
	}
	
	.btn-menu {
		border: 0;
		margin: 3.5rem 0;
		padding: 1rem 2rem;
		background: @primary-color;
		color: @terceary-color;
		font-size: 2.5rem;
	}

	.btn-close {
		border: 0;
		padding: 1rem 2rem;
		background: @primary-color;
		color: @terceary-color;
		font-size: 2rem;
		font-weight: 600;
	}
}


body.search-active {

	.search {
		top: 0;
	}

	.info-links,
	.social-networks {
		display: none;
	}
}


body.navIsActive {
	@media(max-width: 992px) {
		.menu {
			right: 0;
		}
	}
}