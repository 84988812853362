footer {
	position: relative;
	padding: 6rem 0;
	background: @quartenary-color;

	.seal {
		.reset-list;
		margin-bottom: 4rem;

		li {
			.inline-block;
			margin: 0rem 1.5rem;
		}
	}

	.info-links {
		.reset-list;

		li {
			.inline-block;
			margin: 0rem 1rem;
			list-style: none;

			a {
				display: block;
				color: @terceary-color;
				font-size: 1.6rem;
				font-weight: 300;

				.fa {
					.inline-block;
					margin-right: 1rem;
					color:@primary-color;
					font-size: 2rem;
				}

				&:hover {
					text-decoration: none;
				}

				@media(max-width: 480px) {
					font-size: 1.2rem;

					.fa {
						margin-right: .2rem;
						font-size: 1.6rem;
					}
				}
			}
		}
	}
}